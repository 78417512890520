/* #region Layout */
body {
    background-color: $color6;
}
.text-green {
    color: $color1;
}
.container-fluid {
    padding-left: 40px;
    padding-right: 40px;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #999;
    border-color: #999;
    &:hover {
        color: #fff;
    background-color: #999;
    border-color: #999;
    }
}
.section {
    background-color: #fff;
}
.logo-holder {
    border: 1px solid #555;
    box-shadow: 1px 2px 30px #ccc;
    margin-left: 10px;
}
.termsx {
    overflow-y: scroll;
    height: 300px;
    width: 100%;
    border: 1px solid #DDD;
    padding: 10px;
}
.collection-statement {
    border: 1px solid #4a4a4a; background: #fff; overflow: auto; height: 200px; padding: 10px;
    margin-bottom: 20px;
}
.or-separator {
    padding: 30px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 600px;
}
.admin-section {
    background-color: #fff;
    min-height: 600px;
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }

    .action-card {
        width: 18rem;
        margin-right: 1rem;
    }
    .details-card {
        width: 100%;
    }
    .signup-url {
        background-color: #eaeaea;
        padding: 5px 10px;
    }
    .feather {
        width: 16px;
        height: 16px;
        vertical-align: middle;
        margin-top: -4px;
    }


    .sidebar {
        .nav-link {
            &.active {
                color: $color1;
            }
        }
    }
}

.input-group-text {
    border-radius: 0;
    border-color: $color1;
}

.gutter-10 {
    margin-left: -5px;
    margin-right: -5px;
    > [class*="col"] {
        padding-left: 5px;
        padding-right: 5px;
    }
}

main {
    margin-top: 120px;
    min-height: 600px;
}

label {
    margin-bottom: 0;
    margin-top: .5rem;
}

.calculator {
    .option-help {
        padding-left: 27px;
        padding-top: 10px;
        font-weight: normal;
    }
    .card {
        border-radius: 0;
        border: 1px solid #eee;
        &.active {
            box-shadow: 0px 4px 12px #777 !important;
            border: 1px solid $color1;

            .card-header {font-weight: bold;}
        }

        .option-icon {
            height: 20px;
            margin-right: 10px;
        }

        .card-header {
            cursor: pointer;
            p.sub-header {
                font-weight: normal;
            }
        }
        &.inactive {
            label {
                color:#777;
            }
            input, .form-control {
                border-color: #e9e9e9;
                color: #777;
            }
            .input-group-text {
                border-color: #e9e9e9;
            }
        }
    }
    .form-check-input {
        position: relative;
        margin-top: 0.8rem;
        margin-left: 0;
        margin-right: 10px;
    }
    .form-check-label {
        display: inline;
    }
    .calculated-box {
        border: 1px solid $color1;
        text-align: center;
        width: 100%;
        padding: 20px;
        font-size: 18px;
        color: $color1;
    }
    .calculated-box-label {
        width: 100%;
        text-align: center;    
    }
}

.slick {
    &-dots {
        list-style: none;
        padding: 0;
        margin: 0 0 10px;
        display: flex;
        justify-content: center;
        li {
            position: relative;
            & + li {
                margin-left: 24px;
            }
            button {
                display: block;
                font-size: 0;
                height: 10px;
                width: 10px;
                padding: 0;
                background-color: $color3;
                border: 0;
                outline: none !important;
                border-radius: 50%;
                &:hover {
                    background-color: $color4;
                }
            }
            &.slick-active button {
                background-color: $color4;
            }
        }
    }
    &-arrow {
        border: 0;
        padding: 0;
        position: absolute;
        top: calc(50% - 1.25rem);
        right: rem-calc(-60);
        width: rem-calc(40);
        height: rem-calc(40);
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' fill='%23553364'/%3E%3C/svg%3E")
            no-repeat center center;
        background-size: 36px 36px;
        font-size: 0;
        color: transparent;
        z-index: 2;
        &:hover {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' fill='%23742955'/%3E%3C/svg%3E");
        }
        &.slick-prev {
            right: auto;
            left: rem-calc(-60);
            transform: rotate(180deg);
        }
        &.slick-disabled {
            opacity: 0.3;
        }
    }
}

.section {
    overflow: hidden;
    position: relative;
    padding: rem-calc(100) 0;
}
.no-padding-bottom {
    padding-bottom: 0;
}
.no-padding-top {
    padding-top: 0;
}
.narrow {
    max-width: 700px;
    margin: 0 auto;
}
.narrow-830 {
    max-width: 830px;
    margin-left: auto;
    margin-right: auto;
}

.bg-yellow {
    background-color: $color6;
}
.bg-light-blue {
    background-color: $color7;
    .form-control {
        background-color: transparent;
        border-color: $color1;
        color: $color1;
        &:focus,
        &.is-valid:focus,
        .was-validated &:valid:focus {
            color: $color1;
            border-color: $color1;
        }
        &.is-valid,
        .was-validated &:valid {
            border-color: $color1;
        }
        &::placeholder {
            color: $color1;
        }
    }
}

.card {
    border: 0;
    border-radius: rem-calc(5);
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    &-img-link {
        display: block;
        overflow: hidden;
        img {
            display: block;
            max-width: 100%;
            transition: transform 0.2s ease;
        }
    }
    &-header {
        background-color: #fff;
        border-bottom: 0;
    }
    &-body {
        padding: rem-calc(30);
    }
    &:hover {
        .card-img-link img {
            transform: scale(1.05);
        }
    }
    &-hover {
        transition: background-color 0.2s ease;
        &:hover {
            background-color: $color10;
        }
    }
}

.modal {
    &-dialog {
        max-width: 940px;
        min-height: calc(100vh - 3.5rem);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
    }
    &-content {
        border: 0;
        border-radius: rem-calc(5);
        box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
    }
    .close {
        position: absolute;
        bottom: 100%;
        right: 0;
        font-size: rem-calc(40);
        color: #fff;
        text-shadow: none;
        font-weight: 400;
        opacity: 1 !important;
    }
}
#modal-video,
#modalVideo {
    .modal-content {
        background-color: #1f1f1f;
    }
}
#modal-team {
    .modal-body {
        padding: rem-calc(30);
    }
}

.breadcrumb {
    background-color: transparent;
    margin-top: 70px;
    margin-bottom: 0;
    padding-left: 0;
    padding-right: 0;
    &-item {
        & + &::before {
            color: $color3;
        }
        &.active {
            color: $color5;
        }
    }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover {
    background: none !important;
    border: none !important;
    color: $color1 !important;
    font-weight: bold;
}

.accordion .card {
    margin-bottom: rem-calc(22);
    border-radius: rem-calc(0) !important;
    .card-header {
        margin-bottom: 0;
        border-radius: rem-calc(0);
        padding: 0;
        h2 {
            font-size: rem-calc(18);
        }
        .btn-link {
            padding: rem-calc(15) rem-calc(50) rem-calc(15) rem-calc(20);
            background-color: $color10;
            border-radius: rem-calc(0);
            font-size: rem-calc(18);
            display: block;
            width: 100%;
            text-align: left;
            &::after {
                top: 1rem;
            }
            &.collapsed {
                background-color: #fff;
            }
        }
    }
}
.page-item {
    + .page-item {
        margin-left: 10px;
    }
}
.page-link {
    border-radius: rem-calc(5);
    background-color: $color10;
    color: $color3;
    border: 0;
    min-width: rem-calc(36);
    text-align: center;
    &:hover,
    &:focus {
        background-color: $color9;
        color: $color3;
        box-shadow: none;
    }
}
/* #endregion */

/* #region Form */
label {
    font-weight: 500;
    color: $color3;
}
.form-control,
.btn {
    box-shadow: none !important;
}
.form-control {
    border-color: $color3;
    border-radius: rem-calc(0) !important;
    color: $color3;
    height: calc(1.5em + 0.875rem + 2px);
    padding: rem-calc(7) rem-calc(22);
    &::placeholder {
        color: rgba($color3, 0.6);
    }
    &::-ms-clear {
        display: none;
    }
    &:focus,
    &.is-valid:focus,
    .was-validated &:valid:focus {
        color: $color4;
        border-color: $color4;
    }
    &.is-invalid,
    .was-validated &:invalid,
    &.is-invalid:focus,
    .was-validated &:invalid:focus {
        border-color: #f44336;
    }
    &.is-valid,
    .was-validated &:valid {
        border-color: $color3;
        background-image: none;
        padding-right: rem-calc(22);
    }
}
textarea.form-control {
    border-radius: rem-calc(5);
}
.userform {
    div.field {
        &.form-control {
            border: none;
            height: auto;
            padding: 0;
            margin-bottom: 1rem;
        }
        label.left {
            font-weight: 500;
            margin-bottom: 0.5rem;
        }
        &.checkbox {
            position: relative;
            display: block;
            min-height: 1.5rem;
            z-index: 999;
            padding-left: 1.5rem;
            left: auto;
            width: auto;
            height: auto;
            opacity: 1 !important;
            .checkbox {
                overflow: visible;
                margin-top: 2px;
                opacity: 1;
                padding: 0;
                box-sizing: border-box;
            }
        }
    }
    .Actions {
        margin-top: 1.5rem !important;
        input {
            &.action {
                border-radius: 1.5625rem;
                padding-top: 0.75rem;
                padding-bottom: 0.75rem;
                background-color: #742955;
                border: none;
                color: #fff;
                font-weight: 500;
                padding: 0.4375rem 1.375rem;
                box-shadow: none !important;
            }
        }
    }
    input.form-control,
    textarea.form-control,
    .btn {
        box-shadow: none !important;
    }
    input.form-control,
    textarea.form-control {
        border-color: $color3;
        border-radius: rem-calc(20);
        color: $color3;
        height: calc(1.5em + 0.875rem + 2px);
        padding: rem-calc(7) rem-calc(22);
        &::placeholder {
            color: rgba($color3, 0.6);
        }
        &::-ms-clear {
            display: none;
        }
        &:focus,
        &.is-valid:focus,
        .was-validated &:valid:focus {
            color: $color4;
            border-color: $color4;
        }
        &.is-invalid,
        .was-validated &:invalid,
        &.is-invalid:focus,
        .was-validated &:invalid:focus {
            border-color: #f44336;
        }
        &.is-valid,
        .was-validated &:valid {
            border-color: $color3;
            background-image: none;
            padding-right: rem-calc(22);
        }
    }
    textarea.form-control {
        border-radius: rem-calc(5);
        height: auto;
    }
}
.was-validated textarea.form-control:valid,
textarea.form-control.is-valid {
    padding-right: rem-calc(22);
}
.invalid-feedback {
    color: #f44336;
}
.btn {
    border-radius: rem-calc(0);
    font-weight: 500;
    padding: rem-calc(7) rem-calc(22);
    svg {
        //   vertical-align: text-top;
        &[width="24"] {
            vertical-align: -7px;
        }
    }
    &-secondary {
        background-color: $color1;
        border-color: $color1;
        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show > &.dropdown-toggle {
            background-color: #fff;
            border-color: $color1;
            color: $color1;
        }
    }
    &-primary {
        background-color: $color4;
        border-color: $color4;
        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show > &.dropdown-toggle {
            background-color: white;
            border-color: $color4;
            color: $color4;
        }
    }
    &-outline-primary {
        background-color: transparent;
        border-color: $color3;
        color: $color3;
        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show > &.dropdown-toggle {
            background-color: transparent;
            border-color: $color3;
            color: $color3;
        }
    }
    &-link {
        padding: 0;
        color: $color3;
        &:hover,
        &:focus {
            color: $color3;
        }
    }
    &-md {
        border-radius: rem-calc(0);
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(12);
    }
    &-icon {
        padding: rem-calc(8);
        border: 0;
        border-radius: 0;
        color: $color3;
        svg {
            display: block;
        }
        &:hover,
        &:focus,
        &:not(:disabled):not(.disabled).active,
        &:not(:disabled):not(.disabled):active,
        .show > &.dropdown-toggle {
            color: $color4;
        }
    }
    &-play {
        color: #fff !important;
        font-weight: 700;
        text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
        img {
            width: 107px;
            height: 107px;
        }
        span {
            display: block;
            margin-top: -1rem;
        }
    }
    &-w163 {
        min-width: rem-calc(163);
        text-align: left;
        .icon-arrow-right {
            float: right;
            margin-top: 2px;
        }
    }
}
.custom-control-label {
    font-weight: 500;
    font-size: 1rem;
    &::before {
        border-color: $color3;
    }
    a {
        color: $color4;
        text-decoration: underline;
    }
}
.custom-control-input:checked ~ .custom-control-label::before {
    background-color: $color4;
    border-color: $color4;
}
.custom-control-input.is-invalid ~ .custom-control-label,
.was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #f44336;
    &::before {
        border-color: #f44336;
    }
}
.custom-control-input.is-valid ~ .custom-control-label,
.was-validated .custom-control-input:valid ~ .custom-control-label {
    color: $color4;
}
.custom-control-input.is-valid:checked ~ .custom-control-label::before,
.was-validated
    .custom-control-input:valid:checked
    ~ .custom-control-label::before {
    border-color: $color4;
    background-color: $color4;
}

.input-group > .input-group-append > .btn {
    border-top-left-radius: rem-calc(0);
    border-bottom-left-radius: rem-calc(0);
}
.input-group-append {
    position: relative;
    margin-left: rem-calc(-20);
    z-index: 4;
}
/* #endregion */

/* #region Navbar */
.navbar-affix {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1030;
    &.affix {
        position: fixed;
        top: -81px;
    }
}
.topbar {
    padding-left: 40px;
    padding-right: 40px;
    .inner {
        border-bottom: 1px solid rgba($color3, 0.3);
        padding-top: 20px;
        padding-bottom: 20px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }
    a {
        color: $color3;
        &:hover {
            text-decoration: none;
            color: $color4;
        }
        svg {
            vertical-align: rem-calc(-6);
        }
    }
    &-search {
        margin-left: rem-calc(54);
        .form-control {
            background-color: transparent;
            width: 225px;
        }
    }
}
.navbar {
    padding: 11px 20px;
    transition: all 0.15s linear;
    &-brand {
        color: $color1 !important;
        width: 100px;
        height: 80px;
        svg {
            width: 100px;
            height: 80px;
            margin-top: -5px;
        }
    }
    &-collapse {
        justify-content: flex-end;
    }
    &-nav {
        margin-right: rem-calc(-20);
        position: relative;
        .nav-item {
            .nav-link {
                color: $color3;
                font-weight: 500;
                font-size: 0.813rem;
                text-transform: uppercase;
                padding-left: rem-calc(13.5);
                padding-right: rem-calc(13.5);
                position: relative;
                transition: background-position 0.2s ease-in-out;
                background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
                background-position: 100% 0, 0 100%;

                &:hover {
                    color: $color4;
                    transition: background-position 0.2s ease-in-out;
                    background-image: url(../img/main_top_right_lines.svg),
                        url(../img/main_bottom_left_lines.svg);
                }
                &.dropdown-toggle::after {
                    display: none;
                }
            }
            &.show .nav-link {
                color: $color4;
            }
        }
    }
    &-search {
        position: relative;
        .form-control {
            position: absolute;
            right: 0;
            width: rem-calc(40);
            padding-left: rem-calc(19);
            padding-right: rem-calc(19);
            background-color: transparent;
            border-color: transparent;
            color: transparent;
            transition: all 0.3s ease;
            &::placeholder {
                color: transparent;
            }
        }
        .btn {
            position: relative;
            z-index: 2;
        }
        &.active {
            .form-control {
                width: 360px;
                padding-left: rem-calc(22);
                padding-right: rem-calc(50);
                background-color: #fff;
                border-color: $color3;
                color: $color3;
                &::placeholder {
                    color: rgba($color3, 0.6);
                }
            }
        }
    }
    &.scrolled,
    &.navbar-white {
        background-color: #fff;
        box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);
    }
}

.hamburger {
    $this: &;
    height: 40px;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0 -9px 0 10px;
    overflow: visible;
    display: none;
    padding: 10px 9px;
    &-box {
        width: 22px;
        height: 20px;
        display: inline-block;
        position: relative;
    }
    &-inner {
        display: block;
        top: 50%;
        margin-top: -1px;
        &,
        &::before,
        &::after {
            width: 22px;
            height: 2px;
            background-color: $color3;
            position: absolute;
            transition-property: transform;
            transition-duration: 0.15s;
            transition-timing-function: ease;
        }
        &::before,
        &::after {
            content: "";
            display: block;
        }
        &::before {
            top: -6px;
            transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        }
        &::after {
            bottom: -6px;
            transition: bottom 0.075s 0.12s ease,
                transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
        & {
            transition-duration: 0.075s;
            transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
        }
    }
    &.is-active {
        #{$this}-inner {
            transform: rotate(45deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.075s ease, opacity 0.075s 0.12s ease;
            }
            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.075s ease,
                    transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}
.navigation {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 300px;
    z-index: 1040;
    display: none;
    overflow: hidden;
    transform: translate3d(100%, 0, 0);
    transition: transform 0.4s;
    transition-timing-function: cubic-bezier(0.7, 0, 0.3, 1);
    background-color: #fff;
    .navigation-open & {
        transform: translate3d(0, 0, 0);
        transition: transform 0.5s;
    }
    &-header {
        padding: 1rem rem-calc(24);
    }
    .close {
        display: block;
        float: none;
        color: $color3;
        font-size: 2.5rem;
        line-height: 1;
        margin-left: auto;
        transition: all 0.1s ease;
    }
    &-body {
        overflow: hidden;
        position: relative;
        z-index: 2;
    }
    &-backdrop {
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        background-color: rgba(#000, 0.75);
        z-index: 1034;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease;
        .navigation-open & {
            opacity: 1;
            visibility: visible;
        }
    }
    .nav-link {
        font-size: rem-calc(16);
        font-weight: 500;
        padding: rem-calc(8) rem-calc(24);
    }
    .collapse-body {
        padding-left: 20px;
        .nav-link {
            font-family: "proxima_nova";
            font-weight: 400;
        }
    }
}
.navigation-open {
    overflow: hidden !important;
    height: 100% !important;
}

.dropdown {
    &-toggle {
        position: relative;
        padding-right: rem-calc(40);
        &::after {
            display: block;
            position: absolute;
            right: rem-calc(18);
            top: calc(50% - 9px);
            border: 0;
            width: 19px;
            height: 19px;
            background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M20,12l-1.41-1.41L13,16.17V4H11V16.17L5.42,10.58,4,12l8,8Z' fill='%23553364'/%3E%3C/svg%3E")
                no-repeat;
            background-size: 19px 19px;
        }
        &.btn-block {
            text-align: left;
            + .dropdown-menu {
                right: 0;
            }
        }
    }
    &-menu-old {
        margin-top: 0;
        border: 0;
        border-radius: 0;
        padding-top: rem-calc(12);
        padding-bottom: rem-calc(12);
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
        max-height: 300px;
        overflow: auto;
        &.category {
            min-width: 315px;
        }
    }
    &-item {
        color: $color3;
        padding-left: rem-calc(20);
        padding-right: rem-calc(20);
        &:hover,
        &:focus {
            color: $color4;
            background-color: transparent;
            text-decoration: underline;
        }
    }
}
[data-toggle="collapse"] {
    position: relative;
    padding-right: 50px;
    &::after {
        position: absolute;
        content: "";
        right: 16px;
        top: 8px;
        transform: rotate(-90deg);
        width: 24px;
        height: 24px;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M10,6,8.59,7.41,13.17,12,8.59,16.59,10,18l6-6Z' fill='%23553364'/%3E%3C/svg%3E")
            no-repeat center center;
        background-size: 24px 24px;
    }
    &.collapsed::after {
        transform: rotate(90deg);
    }
}

.nav {
    .icon-arrow-right {
        vertical-align: rem-calc(-5);
    }
    &-social {
        align-items: center;
        .nav-item + .nav-item {
            margin-left: 20px;
        }
        .nav-link {
            padding: 0;
            svg {
                display: block;
            }
        }
    }
    &-underline {
        .nav-link:hover {
            text-decoration: underline;
        }
    }
}

.close {
    font-weight: 400;
    text-shadow: none;
    opacity: 1 !important;
}

.icon-arrow-right {
    transform: rotate(-90deg);
}
/* #endregion */

/* #region Footer */
.footer {
    background-color: $color1;
    padding: rem-calc(90) 0 rem-calc(20);
    color: #fff;
    a,
    a:hover {
        color: #fff;
    }
    &-logo {
        display: inline-block;
    }
    address {
        margin-bottom: rem-calc(36);
    }
    &-nav {
        .nav-item {
            margin-bottom: rem-calc(12);
        }
        .nav-link {
            padding: 0;
            font-weight: 500;
        }
    }
}
.copy {
    font-size: rem-calc(14);
    color: $color6;
    margin-top: rem-calc(90);
}
/* #endregion */

.icon-block-img {
    max-width: 60px;
    margin-bottom: 10px;
}

/* #region Chart */
.chart-filter {
    margin-bottom: rem-calc(15);
    border-bottom: solid 1px $color3;
}
.legend-list {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: rem-calc(15);
    .custom-checkbox {
        margin-right: rem-calc(18);
        margin-bottom: rem-calc(9);
    }
}
.chart-dropdown {
    margin-bottom: rem-calc(15);
}
/* #endregion */

/* #region Article */
.atc-wrap {
    height: 100%;
    &.mb-30 {
        height: calc(100% - 30px);
    }
    .card-body {
        flex: unset;
        &.atc-equal {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .btn {
                margin-top: auto;
            }
        }
    }
    .card-img-link {
        position: relative;
        width: 100%;
        display: block;
        padding-top: 56.25%;
        overflow: hidden;
        img {
            width: 100%;
            min-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            object-fit: cover;
        }
    }
    &:hover {
        .card-img-link {
            img {
                transform: translate(-50%, -50%) scale(1.3);
            }
        }
    }
}

.article-slick {
    .slick-track {
        display: flex !important;
    }
    .slick-slide {
        height: inherit !important;
    }
}
/* #endregion */

/* #region Chart */
#assetschart {
    height: 750px;
}
#myChart {
    height: 100%;
}
/* #endregion */


.infographic-img {
    margin-top: 35px;
}