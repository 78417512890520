/* #region Variables */
$color1: #276015;
$color2: #1e668e;
$color3: #4a4a4a;
$color4: #6c8d31;
$color5: #5b5b5b;
$color6: #F1F2DB;
$color7: #D1D588;
$color8: #79cdcf;
$color9: #ecb88a;
$color10: #D1D588;


/* #endregion */

/* #region Function */
@function rem-calc($size) {
  $remSize: $size / 16;
  @return #{$remSize}rem;
}
/* #endregion */

/* #region Global */
  html {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-smoothing: antialiased;
  }
  body{
    color: $color5;
    overflow-x:hidden;
    position: relative;
  }
  *{outline: none!important;}
/* #endregion */

@font-face {
    font-family: 'Dialog';
    src: url('../fonts/Dialog.eot');
    src: url('../fonts/Dialog.eot') format('embedded-opentype'),
         url('../fonts/Dialog.woff2') format('woff2'),
         url('../fonts/Dialog.woff') format('woff'),
         url('../fonts/Dialog.ttf') format('truetype'),
         url('../fonts/Dialog.svg#Dialog') format('svg');
}

@font-face {
    font-family: 'DialogBold';
    src: url('../fonts/DialogBold.eot');
    src: url('../fonts/DialogBold.eot') format('embedded-opentype'),
         url('../fonts/DialogBold.woff2') format('woff2'),
         url('../fonts/DialogBold.woff') format('woff'),
         url('../fonts/DialogBold.ttf') format('truetype'),
         url('../fonts/DialogBold.svg#DialogBold') format('svg');
}

@font-face {
    font-family: 'DialogLight';
    src: url('../fonts/DialogLight.eot');
    src: url('../fonts/DialogLight.eot') format('embedded-opentype'),
         url('../fonts/DialogLight.woff2') format('woff2'),
         url('../fonts/DialogLight.woff') format('woff'),
         url('../fonts/DialogLight.ttf') format('truetype'),
         url('../fonts/DialogLight.svg#DialogLight') format('svg');
}

body {
    font-family: DialogLight;
}
h1 {
    font-weight: normal;
    font-family: DialogBold;
}
h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-weight: normal;
    font-family: Dialog;
}


/* #region Typography */
  .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{
    color: $color3;
  }
  .h1, h1{
    font-size: rem-calc(72);
    line-height: 1;
  }
  .h2, h2{
    font-size: rem-calc(48);
    margin-bottom: rem-calc(32);
  }
  .h3, h3{
    font-size: rem-calc(32);
    margin-bottom: rem-calc(24);
  }
  .h4, h4{
    font-size: rem-calc(26);
  }
  .h5, h5{
    font-size: rem-calc(22);
  }
  .h6, h6{
    font-size: rem-calc(18);
    small{
      font-size: 1rem;
      color: $color5;
      font-weight: 400;
    }
  }
  p:last-child:not(.lead){margin-bottom: 0;}
  a{
    color: $color3;
    &:hover{color: $color4;}
  }
  hr{border-top-color: rgba($color3, .3);}
  
  .fz-16{font-size: 1rem;}
  .font-weight-medium{font-weight: 500;}
  .mt-2rem{margin-top: 2rem!important;}
  .mb-30{margin-bottom: 30px;}
  .p-20{padding:rem-calc(20)!important;}

  .text-bold { font-weight: bold;}
  .text-three{color: $color3;}
  .text-four{color: $color4;}
  .text-five{color: $color5;}
  .text-eight{color: $color8;}
  .text-nine{color: $color9;}
/* #endregion */