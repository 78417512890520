@media (min-width:1200px){
/* #region Layout */
  .container, .container-lg, .container-md, .container-sm, .container-xl{max-width: 1200px;}
/* #endregion */
}

@media (max-width:1400px){
/* #region Homepage */
  // .slick-arrow{
  //   right:0;
  //   &.slick-prev{
  //     right:auto;
  //     left: 0;
  //   }
  // }
  .article-slick .slick-arrow{display: none;}
/* #endregion */
}

@media (max-width:1199px){
/* #region Layout */
/* #endregion */

/* #region Typography */
/* #endregion */

/* #region Navbar */
  .topbar, .navbar{
    padding-left: 20px;
    padding-right: 20px;
  }
  .topbar{display: none;}
  .navbar{
    &-affix{position: fixed;}
    &.scrolled{
      background-color: #fff;
      box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.1);      
      padding:11px 20px;
      .navbar{
        &-brand{
          width: 100px;
          height: 48px;
          svg{
            width: 100px;
            height: 38px;
          }
        }
        &-nav{
          margin-right: 0;
          .nav-item.show-onscroll{
            display: block;
          }
        }
      }
    }
    &-nav{
      margin-right: 0;
      .nav-item{
        display: none;
        &.show-onscroll{display: block;}
      }
    }
  }
  .hamburger{display: block;}
/* #endregion */

/* #region Footer */
/* #endregion */

/* #region Homepage */
  #map{height: 460px;}
  .subscribe-form{margin-left: 0;}
/* #endregion */
}

@media (max-width:991px){
/* #region Layout */
  .container-fluid{
    padding-left: 20px;
    padding-right: 20px;
  }
  .section{
    padding:rem-calc(70) 0;
  }

  .modal-dialog{max-width: 690px;}
/* #endregion */

/* #region Typography */
  .h1, h1{font-size: rem-calc(60);}
  .h2, h2{font-size: rem-calc(40);}
  .h3, h3{font-size: rem-calc(28);}
  .h4, h4{font-size: rem-calc(24);}
  .h5, h5{font-size: rem-calc(20);}
/* #endregion */

/* #region Navbar */
/* #endregion */

/* #region Footer */
  .footer{padding-top: rem-calc(70);}
  .copy{margin-top: rem-calc(70);}
/* #endregion */

/* #region Homepage */
  .subscribe{
    text-align: center;
    &-form{margin-top: 10px;}
  }
  .team-img{width: 300px;}

  .photo-caption-transparent{
    position: relative;
    top:auto;
    left:auto;
    background-color: #fff;
    color: $color5;
    .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{color: $color3;}
  }
/* #endregion */

/* #region Chart */
  #assetschart {
    height: 500px;
  }
/* #endregion */

}

@media (max-width:767px){
/* #region Layout */
  .section{
    padding: rem-calc(50) 0;
  }
  .modal-dialog{
    max-width: 510px;
    margin-top: 40px;
  }
  #modal-team .modal-body{padding:rem-calc(20);}
/* #endregion */

/* #region Typography */
  .h1, h1{font-size: rem-calc(46);}
  .h2, h2{font-size: rem-calc(36);}
  .h3, h3{font-size: rem-calc(26);}
/* #endregion */

/* #region Navbar */
/* #endregion */

/* #region Footer */
/* #endregion */

/* #region Homepage */
  // .parallax{
  //   top: 0;
  //   height: 56vw;
  //   bottom: auto;
  //   width: 100%;
  //   background-size: auto;
  //   &-content{
  //     position: relative;
  //     z-index: 2;
  //     padding-top: rem-calc(50);
  //   }
  // }
  // #for-customers, #for-communities{
  //   padding-top: 56vw;
  // }
  // #for-customers .parallax{
  //   background-image: url(../img/img-02.jpg);
  // }
  // #for-communities .parallax{
  //   background-image: url(../img/img-03.jpg);
  // }
  
  .parallax{
    position: relative;
    top:auto;
    bottom: auto;
    height: 56vw;
    width:100%;
    background-position: center bottom;
    &-content{
      padding-top: rem-calc(50);
    }
    &-section{padding-top: 0;}
  }
  .team-img{
    width: 100%;
    margin-bottom: 30px;
    img{
      width:100%;
    }
  }
/* #endregion */

/* #region Chart */
  #assetschart {
    height: 600px;
  }
/* #endregion */

}

@media (max-width:576px){
/* #region Layout */
  .container{
    padding-left: 20px;
    padding-right: 20px;
  }
  
  section{
    padding: rem-calc(40) 0;
  }
/* #endregion */

/* #region Typography */
  body{font-size: 14px;}
  .h1, h1{
    font-size: rem-calc(44);
    line-height: 1.1;
  }
  .h2, h2{font-size: rem-calc(32);}
  .h3, h3{font-size: rem-calc(26);}
/* #endregion */

/* #region Navbar */
  .navbar{
    padding-top: 11px;
    padding-bottom: 11px;
    &-brand{
      width: 100px;
      height: 48px;
      svg{
        width: 100px;
        height: 38px;
      }
    }
    &-search.active .form-control{
      width: calc(100vw - 80px);
    }
  }
/* #endregion */

/* #region Footer */
  .footer{
    padding-top: rem-calc(40);
    text-align: center;
    address{margin-top: rem-calc(24);}
  }
  .nav-social{
    justify-content: center;
    margin-bottom: rem-calc(32);
  }
  .copy{margin-top: rem-calc(24);}
/* #endregion */

/* #region Homepage */
  .header-home h1{margin-top: rem-calc(50);}
  .article-slick{
    margin-left: -20px;
    margin-right: -20px;
  }
  .article-item{
    padding-left: 20px;
    padding-right: 20px;
  }
/* #endregion */

}
