// #region Homepage
  .header-home{
    position: relative;
    background: url(../img/img-01.jpg) no-repeat center center;
    background-size: cover;
    h1{margin-top: rem-calc(100);}
  }
  .inline-YTPlayer{position: absolute!important;left:0;top:0;width:100%;height:100%;pointer-events: none;}
  #bgVideo{position: absolute!important;left:0;top:0;width:100%;height:100%!important;padding-bottom:0!important;}  

  .map-wrap{
    margin-top: rem-calc(38);
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);    
  }
  #map{
    height: 39.25vw;
  }

  .gm-style{
    font-family: 'proxima_nova';
    line-height: 1.5;
    .gm-style-iw{
      font-size: 1rem;
    }
    .gm-style-iw-c{
      padding:rem-calc(20)!important;
      border-radius: 0;
      background-color: $color6;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.05);
    }
    .gm-style-iw-d{overflow: auto!important;}
    .gm-style-iw-t::after{display: none;}
  }
  .asset-desc{
    text-align: center;
    h4{font-weight: 700;}
  }

  .article-slick{
    margin-left: -15px;
    margin-right: -15px;
  }
  .article-item{
    padding-left: 15px;
    padding-right: 15px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .subscribe{
    overflow: hidden;
    padding:rem-calc(28) 0;
    h4{color: $color1;}
    &-form{
      margin-left: rem-calc(32);
      .form-control{width: 250px;}
    }
  }

  .parallax-section {
    min-height: 400px;
  }

  .parallax{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center left;
    &-right{
      left: auto;
      right: 0;
    }
  }

  .video-inline{
    max-width: 830px;
    margin:0 auto;
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  }
  .chart-inline{
    box-shadow: 0px 2px 32px 0px rgba(0, 0, 0, 0.1);
  }

  .photo-item{
    position: relative;
    display: block;
    border-radius: rem-calc(5);
    overflow: hidden;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
    img{
      display: block;
      width: 100%;
    }
  }
  a.photo-item{
    margin-bottom: 30px;
    &::before{
      position: absolute;
      content: '';
      left:0;
      top:0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 2;
      transition: opacity .2s ease;
      opacity: 0;
      visibility: hidden;
    }
    &::after{
      position: absolute;
      content: '';
      top:calc(50% - 24px);
      left:calc(50% - 24px);
      height: 48px;
      width: 48px;
      background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M18,13v7H4V6H9A6.3,6.3,0,0,1,9.5,4H4A2,2,0,0,0,2,6V20a2,2,0,0,0,2,2H18a2,2,0,0,0,2-2V15Zm-1.5,5H5.5l2.75-3.53,2,2.36L13,13.29Zm2.8-9.11A4.49,4.49,0,1,0,15.49,11a4.43,4.43,0,0,0,2.39-.7L21,13.42,22.42,12ZM15.5,9A2.5,2.5,0,1,1,18,6.5,2.5,2.5,0,0,1,15.5,9Z' fill='%23fff'/%3E%3C/svg%3E") no-repeat;
      background-size: 48px 48px;
      z-index: 3;
      transition: opacity .2s ease;
      opacity: 0;
      visibility: hidden;
    }
    &:hover{
      &::before, &::after{
        opacity: 1;
        visibility: visible;
      }
    }
  }
  .photo-caption{
    position: absolute;
    left:0;
    right:0;
    bottom:0;
    background-color: #ffffff;
    padding:rem-calc(13) rem-calc(20);
    color: $color5;
    border-radius: 0 0 rem-calc(5) rem-calc(5);
    &-transparent{
      background-color: rgba(0, 0, 0, 0.3);
      padding:rem-calc(20);
      color: #fff;
      .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6{color: #fff;}
    }
  }
  .image-slick, .text-slick{
    margin-left: -15px;
    margin-right: -15px;
  }
  .image-item, .text-item{
    padding-left: 15px;
    padding-right: 15px;
    margin:10px auto 30px;
  }

  .filter-wrap{
    background-color: $color10;
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
    .form-control{
      background-color: transparent;
    }
  }

  .team-item{margin-bottom: 30px;}
  .team-img{width: 374px;}
  .hidden{
      display: none;
  }
  .full-width{
      width: 100%;
  }
  #SearchResults{
      li{
          margin-bottom: 20px;
      }
  }
// #endregion

/*Monthly Performance Section*/

.wrap-revenue{
  font-weight: 700;
  line-height: 1.9;

}
/*End Monthly*/
// #region Calculator
.border-bottom {
  border-color: #c9bece;
}

.calculator-wrap {
  margin-bottom: rem-calc(35);
  .cal-left {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: rem-calc(20);
    p, label {
      font-size: rem-calc(18);
      font-weight: 600;
      color: $color3;
      .data {
        color: $color1;
        text-decoration: underline;
        &:empty {
          min-width: 7px;
          height: 100%;
          position: relative;
          display: inline-block;
          &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 1px;
            bottom: 6px;
            background: $color1;
          }
        }
      }
    }
    p {
      margin-bottom: rem-calc(35)
    }
    .ui-slider {
      margin-bottom: rem-calc(20);

    }
    .ui-state-default, 
    .ui-widget-content .ui-state-default {
      border-radius: 50%;
      background: #6ba1bf;
      border-color: #6ba1bf;
    }

    .ui-widget.ui-widget-content {
      border: none;
      border-radius: 10em;
      background: #f7e2cc;
      height: 10px;
    }

    .ui-slider-horizontal .ui-slider-range-min {
      background: #ecb88a;
      border-radius: 10em;
    }
  }

  .cal-right {
     margin-bottom: rem-calc(20);
    .list-right {
      display: flex;
      align-items: center;
      margin-bottom: rem-calc(10);
      img {
        width: 79px;
      }
      .text {
        margin-left: rem-calc(15);
        p {
          margin-bottom: 0;
          font-size: rem-calc(18);
          font-weight: 600;
          color: $color3;
        }
        small {
          font-size: rem-calc(14);
          color: $color3;
        }
      }
    }
  }
  .form-control {
    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;
    position: relative;
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath d='M20 12l-1.41-1.41L13 16.17V4h-2v12.17l-5.58-5.59L4 12l8 8z' fill='%23553364'/%3E%3C/svg%3E") no-repeat;
    background-position: calc(100% - 15px) center;
    background-size: 19px 19px;
    padding-right: rem-calc(40);
  }
}

.note {
  font-size: rem-calc(14);
}

.modal-sm {
  max-width: 500px;
}
// #endregion